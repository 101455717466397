/* @import url('https://fonts.googleapis.com/css2?family=Lobster&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Sansita:ital,wght@0,400;0,700;0,800;0,900;1,400;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap');
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-family: 'Roboto', sans-serif;
  font-family: 'Roboto', sans-serif;
} */

/* .App{
  background-color: rgba(128, 128, 128, 0.075);
} */

.custom-scroll-to-top {
  background-color: #007bff; /* Arka plan rengi */
  border-radius: 50%;
  width: 50px; /* Genişlik */
  height: 50px; /* Yükseklik */
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Gölge efekti */
}
.react-calendar {
  background-color: #F1F1F1 !important;
}


.custom-scroll-to-top:hover {
  background-color: #0056b3; /* Hover durumu için arka plan rengi */
}
