.birthday {
    background-color: red !important;
    color: white;
  }
  
  .info-box {
    margin-left: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    border-radius: 4px;
  }
  