.mySwiper {
    height: 60vh;
    max-width: 1170px;
    padding-top: 20px;
  }
/* Swiper Pagination */
.swiper-pagination {
  position: absolute;
  bottom: 10px; /* Rakamları fotoğrafın altına taşır */
  width: 100%;

}

.swiper-pagination-bullet {
  background: none;
  color: white !important; /* Varsayılan beyaz renk */
  opacity: 1 !important;
  font-size: 27px; /* Rakamları büyütmek */
  font-weight: 700;
  margin-right: 40px !important; /* Rakamlar arasına mesafe eklemek */
  cursor: pointer; /* Mouse imlecini pointer yapar */
  padding-bottom: 20px;
  position: relative;
  right: 300px;
  bottom: 10px;

}

.swiper-pagination-bullet-active {
  color: #0086C9 !important; /* Aktif olan rakamı mavi yap */
  font-weight: bold; /* Aktif rakamı kalın yap */
}

  