/* .slick-cloned {
    width: 200px !important;
} */
/* .slick-slide {
    width: 200px !important;
} */

.mySwiper {
    width: 100%;
    height: 65vh;
    position: relative;
  }
  
  .slide-content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
  .slide-content img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.7);
  }
  
  .slide-text {
    position: absolute;
    color: #fff;
    text-align: center;
    animation: fadeInUp 1s ease-in-out;
    right: 100px;
  }
  
  .slide-text h2 {
    font-size: 3rem;
    margin: 0;
    padding: 20px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
  }
  .slick-dots {
    display: none !important;
  }
  
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  