.slider-container {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  background-color: white; /* Arkaplan rengini ayarlayın */
  padding: 10px 0;
  max-width: 700px;
  color: white;
}

.slider { 
  display: inline-block;
  white-space: nowrap;
  animation: scroll 30s linear infinite;
  background-color: white;
  color: black;
}

.brand-item {
  display: inline-block;
  padding: 0 20px; /* Gerektiğinde aralıkları ayarlayın */
  font-family: 'Nunito Sans', sans-serif; /* Tutarlı font */
  font-size: 16px;
  position: relative;
}

.brand-item::before,
.brand-item::after {
  content: "♦"; /* İsteğinize göre sembolü ayarlayın */
  padding: 0 10px;
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Sol ve sağ sancak tasarımı */
.left-banner, .right-banner {
  position: absolute;
  top: -11px;
  bottom: 0;
  width: 50px; /* Sancak genişliği */
  background-color: white; /* Sancak rengi */
  z-index: 1;
}

.left-banner {
  left: 0;
  background-image: url('./download.png'); /* Sol sancak için görsel */
  background-repeat: no-repeat;
  background-position: center;
}

.right-banner {
  right: 0;
  background-image: url('./download.png'); /* Sol sancak için görsel */
  background-repeat: no-repeat;
  background-position: center;
}
